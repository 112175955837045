<template>
  <YoTable
    :api-endpoint="apiEndpoint"
    :title-tbl="titleTbl"
    :sub-title-tbl="`${this.$t('River Boat')} Hulu Migas`"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label="
      this.$t('KeteranganTable') + '~' + this.$t('Form Land Vehicle') + '~'
    "
    bottom-icon="GitMergeIcon"
    info-page-ability="Manage ( All / Hulu Migas / SAL)"
    placeholder-search="Nama Lengkap"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable
  },
  data() {
    return {
      apiEndpoint: 'dm_river_boat_hm',
      titleTbl: 'DATA MASTER',
      fields: [
        // {
        //   minWidth: '130px',
        //   key: 'group.name',
        //   label: 'GROUP',
        //   placeholder: '',
        //   sortable: true,
        //   input: { type: 'input-noarea', disabled: true },
        //   searchModel: 'group.name',
        //   search: {
        //     type: 'text',
        //   },
        // },
        {
          key: 'no_pkk',
          label: 'NO PKK',
          placeholder: 'Hanya Angka',
          sortable: true,
          stickyColumn: true,
          input: { type: 'number' },
          searchModel: 'no_pkk',
          search: {
            type: 'number'
          }
        },
        {
          key: 'expired_at',
          label: 'Expired AT',
          sortable: 'date',
          placeholder: '',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: false
          },
          searchModel: 'expired_at',
          search: {
            type: 'date'
          }
        },
        {
          key: 'boat_name',
          label: 'Nama Kapal',
          sortable: true,
          placeholder: 'Nama Kapal',
          input: { type: 'input-noarea' },
          searchModel: 'boat_name',
          search: {
            type: 'text'
          }
        },
        {
          key: 'gt',
          label: 'GT',
          placeholder: 'Hanya Angka',
          sortable: true,
          input: { type: 'number-append', append: '' },
          searchModel: 'gt',
          search: {
            type: 'number'
          }
        },
        {
          key: 'loa',
          label: 'loa (M)',
          placeholder: 'Hanya Angka',
          sortable: true,
          input: { type: 'number-append', append: 'M' },
          searchModel: 'loa',
          search: {
            type: 'number'
          }
        },
        // {
        //   key: 'volume',
        //   label: 'volume',
        //   placeholder: 'Hanya Angka',
        //   sortable: true,
        //   input: { type: 'number-append', append: '' },
        //   searchModel: 'volume',
        //   search: {
        //     type: 'number',
        //   },
        // },
        // {
        //   key: 'no_doc',
        //   label: 'Nomor Document',
        //   sortable: true,
        //   placeholder: '...',
        //   input: { type: 'input-noarea' },
        //   searchModel: 'no_doc',
        //   search: {
        //     type: 'text',
        //   },
        // },
        {
          key: 'captain_name',
          label: 'Captain Name',
          sortable: true,
          placeholder: 'Albert sinaga',
          input: { type: 'input-noarea' },
          searchModel: 'captain_name',
          search: {
            type: 'text'
          }
        },
        {
          key: 'captain_telp',
          label: 'Captain TELP',
          placeholder: 'Hanya Angka',
          sortable: true,
          input: { type: 'number' },
          searchModel: 'captain_telp',
          search: {
            type: 'number'
          }
        },

        {
          searchModel: 'dm_shipping_agent.shipping_name',
          search: {
            type: 'text'
          },
          key: 'dm_shipping_agent.shipping_name',
          columnKey: 'dm_shipping_agent_id',
          label: 'Shipping Agent *',
          sortable: true,
          input: {
            type: 'select',
            requestAPI: 'allow',
            // unique1: 'no_ktp',
            // unique2: 'capacity',
            optionsApiEndpoint: 'dm_shipping_agent',
            resolveOptionsResponse(response) {
              return response.data.dm_shipping_agents.map((value) => ({
                label: `${value.shipping_name}`,
                value: value.id
              }))
            },
            reduce({ value }) {
              return value // as ID DATA
            }
          }
        },

        {
          key: 'user.full_name',
          label: 'Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
          searchModel: 'user.full_name',
          search: {
            type: 'text'
          }
        },
        {
          searchModel: 'user.phone',
          search: {
            type: 'number'
          },
          key: 'user.phone',
          label: 'Phone of Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true }
        },
        {
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'created_at',
          search: {
            type: 'date'
          }
        },
        {
          key: 'updated_at',
          label: 'Updated AT',
          sortable: 'date',
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'updated_at',
          search: {
            type: 'date'
          }
        }
      ]
    }
  },
  computed: {
    newDataTemplate() {
      return {
        full_name: '',
        date_of_birth: moment(),
        no_ktp: '',
        phone: '',
        address: ''
      }
    }
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.dm_river_boat_hms
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    }
  }
}
</script>
